import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import PropTypes from "prop-types";

// components
import GlobalSearch from "../shared/GlobalSearch";

// styles
import "../../assets/styles/not-found/not-found.scss";


const NotFound = ({ message }) => {
  return (
    <div className="not-found--wrapper">
      <div className="not-found--container">
        <h1 className="not-found--title">Sorry, that page doesn't exist any more</h1>
        <div className="not-found--body">
          {message || <p className="not-found--text">Try to search what you are interested in:</p>}
        </div>
        <GlobalSearch />
        <div className="not-found--divider">
          <span className="not-found--divider-text">or</span>
        </div>
        <Link to="/" className="not-found--go-home-container">
          <Button
            size="large"
            className="not-found--go-home-button"
            type="ghost"
          >
            Go To Home
          </Button>
        </Link>
      </div>
    </div>
  );
};

NotFound.propTypes = {
  message: PropTypes.node,
};

export default NotFound;

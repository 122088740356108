import React, { useRef, useState, useCallback, useEffect } from "react";
import { Input, Row, Col } from "antd";
import { Link, useHistory } from "react-router-dom";
import lodash from "lodash";

// api
import storefront from "../../api/storefront";

// hooks
import useAlgoliaBulkSearch, { buildSearchQuery } from "../../hooks/useAlgoliaBulkSearch";
import useDimensions from "../../hooks/useDimensions";

// helpers
import { formatPrice, fullyDecodeURL } from "../../helpers";
import { NO_IMAGE_URL } from "../../constants";

// assets
import SvgGreySearchIcon from "../Icons/GreySearchIcon";

const SEARCH_RESULTS_PATH = "/search-results";


const GlobalSearch = () => {
  const [cursor, setCursor] = useState(-1);
  const [search, setSearch] = useState("");
  const [searchedItemsLength, setSearchedItemsLength] = useState(0);
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchFocused, setSearchFocused] = useState(false);
  const [activeSearchElementRef, setActiveSearchElementRef] = useState(null);
  const [firstActiveSearchElementRef, setFirstActiveSearchElementRef] = useState(null);

  const wrapperRef = useRef(null);
  const searchResultsRef = useRef();
  const mobileWrapperRef = useRef(null);

  const desktopInputRef = useRef();
  const mobileInputRef = useRef();

  const history = useHistory();

  const [mainContainerRef, { width }] = useDimensions({ liveMeasure: false });

  const { results, loading, fetch } = useAlgoliaBulkSearch({ queries: buildSearchQuery("") });

  useEffect(() => {
    if (!results || !searchFocused) {
      return;
    }

    const {
      Item_query_suggestions
    } = results;

    const Item = results.Item;
    const Category = results.Category;
    const Period = results.Period;
    const CatalogElement = results.CatalogElement;
    const Artisan = results.Artisan;
    const Dealer = results.Dealer;

    const materialsList = CatalogElement?.hits?.filter(({ catalog_title }) => catalog_title === "Material") || [];
    const originsList = CatalogElement?.hits?.filter(({ catalog_title }) => catalog_title === "Origin") || [];

    const searchResults = [];
    const orderedSearchResults = {
      Suggestion: Item_query_suggestions,
      Category,
      Period,
      Material: { hits: materialsList.map(item => ({ ...item, searchType: "Material" })) },
      Origin: { hits: originsList.map(item => ({ ...item, searchType: "Origin" })) },
      Artisan,
      Dealer,
      Item
    };

    Object.keys(orderedSearchResults).forEach(key => {
      if (orderedSearchResults[key]) {
        const resultTypeList = orderedSearchResults[key].hits.map(item => {
          return ({
            ...item,
            searchType: key,
          });
        });

        searchResults.push(...resultTypeList);
      }
    });

    const isOnlyItemsFound = !Category.hits.length &&
    !Period.hits.length &&
    !CatalogElement.hits.length &&
    !Artisan.hits.length &&
    !Dealer.hits.length &&
    !Item_query_suggestions.hits.length

    setSearchedItemsLength(Item.hits.length);
    setSearchOptions(searchResults);
    setCursor(isOnlyItemsFound ? -1 : 0);
  }, [results]);


  useEffect(() => {
    // Hide result if clicked on outside of element
    function handleClickOutside(event) {
      if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
        return;
      }
      if (mobileWrapperRef.current && mobileWrapperRef.current.contains(event.target)) {
        return;
      }

      setSearchFocused(false);
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);

    // Unbind the event listener on clean up
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef, mobileWrapperRef]);

  useEffect(() => {
    if (activeSearchElementRef) {
      activeSearchElementRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }

    if (cursor === -1 && firstActiveSearchElementRef) {
      firstActiveSearchElementRef.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }, [cursor]);

  const handleSelectSearchElement = () => {
    //eslint-disable-next-line
    let urlToRedirect;

    if (searchOptions[cursor] && searchOptions[cursor].searchType) {
      if (searchOptions[cursor].searchType === "Suggestion") {

        history.replace(`/search-results?search=${searchOptions[cursor].query}`);
        history.go(0);

        return;
      }
      if (searchOptions[cursor].searchType === "categories") {
        urlToRedirect = `/${searchOptions[cursor].url}`;
      }
      if (searchOptions[cursor].searchType === "period") {
        urlToRedirect = `/period/${searchOptions[cursor].url}`;
      }
      if (searchOptions[cursor].searchType === "items") {
        urlToRedirect = `/items/listings/${searchOptions[cursor].url}`;
      }
      if (searchOptions[cursor].searchType === "dealers") {
        urlToRedirect = `/directory/dealers/${searchOptions[cursor].url}`;
      }
      if (searchOptions[cursor].searchType === "artisans") {
        urlToRedirect = `/knowledge/artisan/${searchOptions[cursor].url}`;
      }
      if (searchOptions[cursor].searchType === "origin") {
        urlToRedirect = `/knowledge/origin/${searchOptions[cursor].url}`;
      }
      if (searchOptions[cursor].searchType === "material") {
        urlToRedirect = `/knowledge/material/${searchOptions[cursor].url}`;
      }
    }

    history.replace(`${urlToRedirect || "search-results"}?search=${search}`);
    setSearchFocused(false);
  };


  const handleFocusSearchField = () => {
    setSearchFocused(true);
    if (lodash.isEmpty(searchOptions) && search) {
      fetch(buildSearchQuery(search));
    }
  };

  const onSearch = useCallback(lodash.debounce(value => {

    fetch(buildSearchQuery(value));
  }, 200), []);

  const handleChange = value => {
    const encodedValueForUrl = encodeURIComponent(fullyDecodeURL(value));

    onSearch(value);
    setSearch(encodedValueForUrl);
  };

  const handleUnFocus = ref => {
    if (ref.current) {
      ref.current.blur();
    }
  };

  const handleSearch = ref => {
    if (!search) {
      history.push({ pathname: "/antiques" });
    } else if (cursor < 0) {
      history.push({ pathname: SEARCH_RESULTS_PATH, search: `?search=${search}` });
    } else {
      handleSelectSearchElement();
    }

    setSearchFocused(false);

    handleUnFocus(ref);
  };


  const handleKeyDown = (e) => {
    if (e.keyCode === 38 && cursor >= -1) {
      e.preventDefault();
      setCursor(cursor - 1);
    }

    if (e.keyCode === 40 && cursor <= searchOptions.length) setCursor(cursor + 1);
  };

  const handleRedirect = (url) => {
    history.replace(`${url}?search=${search}`);
    setSearchFocused(false);
    storefront.createSearchQuery({ query: search });
  };

  const renderSearchResult = (result) => {
    return result.map((item, index) => {
      const activeItemClass = cursor === index ? "search-result-active-item" : null
      const labelColors = {
        Category: "#CB6157",
        Period: "#3A98D9",
        Material: "#C0C0C0",
        Origin: "#7AD7C4",
        Artisan: "#F7A327",
        Dealer: "#990099",
        HomeAndLiving: "#4b0082",
      };
      const urlToRedirectTo = (item.searchType === "Category") ?
      `/${item.url}` :
        (item.searchType === "Period") ?
        `/period/${item.url}` :
          (item.searchType === "homeAndLiving")
            ? `/${item.url}`
            : (item.searchType === "Material") ?
          `/knowledge/material/${item.url}` :
          `/knowledge/origin/${item.url}`;
      let itemStatus = "";
      if (item.item_is_reserved) {
        itemStatus = "Reserved";
      } else if (item.make_this_price_poa) {
        itemStatus = "POA"
      }

      switch (item.searchType) {
        case "Item":
          return (
            <div
              className={`header--search-result-row header--search-result-row_item ${activeItemClass}`}
              key={index}
              onClick={() => {
                handleRedirect(`/items/listings/${item.url}`);
              }}
              tabIndex={index}
              ref={ref => {
                index === cursor && setActiveSearchElementRef(ref)
                index === 0 && setFirstActiveSearchElementRef(ref);
              }}
            >
              <Row
                gutter={[10, 10]}
                type="flex"
                style={{ alignItems: "flex-start", margin: "0" }}
                className="item-row"
              >
                <Col xxl={3} xl={3} lg={3} md={3} sm={7} xs={7}>
                  <img src={item.image || NO_IMAGE_URL} alt="logo" />
                </Col>
                <Col xxl={21} xl={21} lg={21} md={21} sm={17} xs={17}>
                  <div className="header--search-result-row--item-info">
                    <h3 className="header--search-result-row--title">{item.title}</h3>
                    <p className="header--search-result-row--description">{item.description}</p>
                    <p>
                      {
                        itemStatus
                          ? itemStatus
                          : item.make_this_price_poa
                            ? "POA"
                            : `£ ${formatPrice(item.price)}`
                      }
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          );
        case "Category":
        case "Period":
        case "Material":
        case "Origin":
        case "HomeAndLiving":
          return (
            <div
              className={`header--search-result-row ${activeItemClass}`}
              key={index}
              onClick={() => {
                handleRedirect(urlToRedirectTo);
              }}
              tabIndex={index}
              ref={ref => {
                index === cursor && setActiveSearchElementRef(ref);
                index === 0 && setFirstActiveSearchElementRef(ref);
              }}
            >
              <Row
                gutter={[30, 30]}
                type="flex"
                style={{ alignItems: "center", margin: "0" }}
                className="item-row"
              >
                <Col xl={18} lg={17} md={18} sm={18} xs={14}>
                  <span className="header--search-result-row--title">
                    <span>{item.title}</span>
                    {item.itemsCount ? (
                      <span className="header--search-result-row--title-items-count">
                        ({item.itemsCount})
                      </span>
                    ) : null}
                  </span>
                </Col>
                <Col xl={6} lg={7} md={6} sm={6} xs={10}>
                  <span
                    className="header--search-result-row--search-type"
                    style={{ background: labelColors[item.searchType] }}
                  >
                    {item.searchType.replace("And", " & ").toLowerCase()}
                  </span>
                </Col>
              </Row>
            </div>
          );
        case "Artisan":
          return (
            <div
              className={`header--search-result-row ${activeItemClass}`}
              key={index}
              onClick={() => {
                handleRedirect(`/knowledge/artisan/${item.url}`);
              }}
              tabIndex={index}
              ref={ref => {
                index === cursor && setActiveSearchElementRef(ref);
                index === 0 && setFirstActiveSearchElementRef(ref);
              }}
            >
              <Row
                gutter={[30, 30]}
                type="flex"
                style={{ alignItems: "center", margin: "0" }}
                className="item-row"
              >
                <Col xxl={20} xl={18} lg={17} md={18} sm={18} xs={14}>
                  <span
                    className="header--search-result-row--title">{item.name}</span>
                </Col>
                <Col xxl={4} xl={6} lg={7} md={6} sm={6} xs={10}>
                  <span
                    className="header--search-result-row--search-type"
                    style={{ background: labelColors[item.searchType] }}
                  >
                    {item.searchType}
                  </span>
                </Col>
              </Row>
            </div>
          );
        case "Dealer":
          return (
            <div
              className={`header--search-result-row ${activeItemClass}`}
              onClick={() => {
                handleRedirect(`/directory/dealers/${item.url}`);
              }}
              key={index}
              tabIndex={index}
              ref={ref => {
                index === cursor && setActiveSearchElementRef(ref);
                index === 0 && setFirstActiveSearchElementRef(ref);
              }}
            >
              <Row
                gutter={[30, 30]}
                type="flex"
                style={{ alignItems: "center", margin: "0" }}
                className="item-row"
              >
                <Col xxl={20} xl={18} lg={17} md={18} sm={18} xs={14}>
                  <span
                    className="header--search-result-row--title">{item.business_name}</span>
                </Col>
                <Col xxl={4} xl={6} lg={7} md={6} sm={6} xs={10}>
                  <span
                    className="header--search-result-row--search-type"
                    style={{ background: labelColors[item.searchType] }}
                  >
                    {item.searchType}
                  </span>
                </Col>
              </Row>
            </div>
          );
        case "Suggestion":
          return (
            <Link
              to={`/search-results?search=${item.query}`}
              className={`header--search-result-row ${activeItemClass}`}
              key={`${item.query}-${index}`}
              onClick={() => setSearchFocused(false)}
            >
              <Row
                className="item-row"
                gutter={[30, 10]}
              >
                <Col xs={24}>
                  <span className="header--search-result-row--title">
                    { item.query }
                  </span>
                </Col>
              </Row>

            </Link>
          );
        default:
          return null;
      }
    });
  };

  return (
    <div ref={mainContainerRef}>
      <div className="header--search lg-view-hidden" ref={wrapperRef}>
        <div>
          <Input.Search
            placeholder="Search for Items, Period, Artisans"
            onChange={e => handleChange(e.target.value)}
            onFocus={handleFocusSearchField}
            size="large"
            enterButton={null}
            addonBefore={<SvgGreySearchIcon/>}
            value={fullyDecodeURL(search)}
            onSearch={() => handleSearch(desktopInputRef)}
            onKeyDown={(e) => handleKeyDown(e)}
            ref={desktopInputRef}
          />
        </div>
        <div className={`header--search-result ${!searchFocused ? "hidden" : ""}`} ref={searchResultsRef}>
          {
            !lodash.isEmpty(searchOptions) &&
                searchedItemsLength >= 5 &&
                search &&
                <Link to={`/search-results?search=${search}`}>
                  <span
                    className="header--show-more"
                    onClick={() => {
                      setSearchFocused(false)
                      if (history.location.pathname === SEARCH_RESULTS_PATH) {
                        history.go(0);
                      }
                    }}
                  >
                    Show all results...
                  </span>
                </Link>
          }
          {search && renderSearchResult(searchOptions)}
        </div>
      </div>
      <div className="header--mobile-search" ref={mobileWrapperRef}>
        <div className="header--mobile-search-container">
          <Input.Search
            placeholder="Search for Items, Period, Artisans"
            onChange={e => handleChange(e.target.value)}
            onFocus={handleFocusSearchField}
            size="large"
            enterButton={null}
            addonBefore={<SvgGreySearchIcon/>}
            // loading={loading}
            value={fullyDecodeURL(search)}
            onSearch={() => handleSearch(mobileInputRef)}
            ref={mobileInputRef}
            className="header--mobile-search-container--search"
          />
        </div>

        <div className={`header--search-result ${!searchFocused ? "hidden" : ""}`} ref={searchResultsRef}>
          {!lodash.isEmpty(searchOptions) && searchedItemsLength >= 5 && search && (
            <Link to={`/search-results?search=${search}`}>
              <span
                className="header--show-more"
                onClick={() => {
                  setSearchFocused(false)
                  if (history.location.pathname === SEARCH_RESULTS_PATH) {
                    history.go(0);
                  }
                }}
              >
                Show all results...
              </span>
            </Link>
          )}
          {width < 992 && search && renderSearchResult(searchOptions)}
        </div>
      </div>
    </div>
  );
};

export default GlobalSearch;
